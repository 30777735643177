import { ref } from 'vue'
import { WhatsAppSendTemplate } from '../../api'

export default () => {
  const isSending = ref(false)
  const sendTemplate = async ({
    phoneNumberId = '',
    toPhoneNumber = '',
    templateName = '',
    language = 'zh_TW'
  } = {}) => {
    try {
      isSending.value = true
      await WhatsAppSendTemplate({ phoneNumberId, toPhoneNumber, templateName, language })
      isSending.value = false
    } catch (err) {
      isSending.value = false
      throw new Error(err)
    }
  }
  return { isSending, sendTemplate }
}
