import { ref } from 'vue'
import { WhatsAppGetPhoneNumbers } from '../../api'

export default () => {
  const isPhoneNumbersLoading = ref(false)
  const phoneNumbers = ref(null)
  const fetchPhoneNumbers = async ({ wabaId }) => {
    try {
      isPhoneNumbersLoading.value = true
      const { data } = await WhatsAppGetPhoneNumbers({ wabaId })
      isPhoneNumbersLoading.value = false
      phoneNumbers.value = data
    } catch (err) {
      isPhoneNumbersLoading.value = false
      throw new Error(err)
    }
  }
  return { isPhoneNumbersLoading, phoneNumbers, fetchPhoneNumbers }
}
