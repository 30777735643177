import { ref } from 'vue'
import { WhatsAppGetAccessToken } from '../../api'

// 用 login 取得的 code 再取得 access token
export default () => {
  const isAccessTokenLoading = ref(false)
  const accessToken = ref('')
  const fetchAccessToken = async ({ code }) => {
    try {
      isAccessTokenLoading.value = true
      const { access_token } = await WhatsAppGetAccessToken({ code })
      isAccessTokenLoading.value = false
      accessToken.value = access_token || ''
    } catch (err) {
      isAccessTokenLoading.value = false
      throw new Error(err)
    }
  }
  return { isAccessTokenLoading, accessToken, fetchAccessToken }
}
