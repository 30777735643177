import { ref } from 'vue'
import { WhatsAppGetWabaList } from '../../api'

// 取得共享的 WABA 清單
export default () => {
  const isWabaListLoading = ref(false)
  const wabaList = ref(false)
  const fetchWabaList = async () => {
    try {
      isWabaListLoading.value = true
      const { data } = await WhatsAppGetWabaList()
      isWabaListLoading.value = false
      wabaList.value = data
    } catch (err) {
      isWabaListLoading.value = false
      throw new Error(err)
    }
  }
  return { isWabaListLoading, wabaList, fetchWabaList }
}
