import { ref } from 'vue'
import { WhatsAppGetTemplateList } from '../../api'

export default () => {
  const isTemplateListLoading = ref(false)
  const templateList = ref(null)
  const fetchTemplateList = async ({ wabaId }) => {
    try {
      isTemplateListLoading.value = true
      const { data } = await WhatsAppGetTemplateList({ wabaId })
      isTemplateListLoading.value = false
      templateList.value = data
    } catch (err) {
      isTemplateListLoading.value = false
      throw new Error(err)
    }
  }
  return { isTemplateListLoading, templateList, fetchTemplateList }
}
