<template>
  <div class="bg-light position-relative">
    <div class="w-100 py-4">
      <div class="card m-auto shadow-sm w-50">
        <div class="card-body">
          <div class="py-4">
            <h4>Step 1: Login</h4>
            <button
              @click="launchWhatsAppSignup()"
              style="background-color: #1877f2; border: 0; border-radius: 4px; color: #fff; cursor: pointer; font-family: Helvetica, Arial, sans-serif; font-size: 16px; font-weight: bold; height: 40px; padding: 0 24px;"
            >
              Login with Facebook
            </button>
          </div>
          <div class="position-relative">
            <div class="py-4">
              <h4>Step 2: Choose WABA</h4>
              <div class="form-group">
                <label class="col-form-label">
                  <span class="text-danger">*</span>
                  Choose WABA
                </label>
                <select
                  v-model="wabaValue"
                  :disabled="isStep1Loading || wabaOption.length === 0"
                  class="form-select"
                  @change="handleSelectWaba"
                >
                  <option value="" disabled selected>
                    {{ isStep1Loading ? 'Loading...' : 'Please Choose WABA' }}
                  </option>
                  <option v-for="item in wabaOption" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="py-4">
              <h4>Step 3: Choose Template</h4>
              <div class="form-group">
                <label class="col-form-label">
                  <span class="text-danger">*</span>
                  Choose Template
                </label>
                <select
                  v-model="templateValue"
                  :disabled="isStep1Loading || templateOption.length === 0"
                  class="form-select"
                >
                  <option value="" disabled selected>
                    {{ isStep1Loading ? 'Loading...' : 'Please Choose Template' }}
                  </option>
                  <option v-for="item in templateOption" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="py-4">
              <h4>Step 4: Send</h4>
              <div class="form-group">
                <label class="col-form-label">
                  <span class="text-danger">*</span>
                  Set recipient phone number
                </label>
                <input type="text" v-model="recipientPhoneNumber" class="form-control">
                <button class="btn btn-primary mt-2" :disabled="isSending || !wabaValue || !templateValue || !recipientPhoneNumber" @click="send">
                  Send
                </button>
              </div>
            </div>
            <div v-if="!wabaOption.length && !templateOption.length" class="position-absolute cover"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import Swal from 'sweetalert2'
import useFetchAccessToken from '../composables/whatsApp/useFetchAccessToken'
import useFetchWabaList from '../composables/whatsApp/useFetchWABAList'
import useFetchTemplateList from '../composables/whatsApp/useFetchTemplateList'
import useFetchPhoneNumbers from '../composables/whatsApp/useFetchPhoneNumbers'
import useSendTemplate from '../composables/whatsApp/useSendTemplate'

const toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000 })
const successToast = (title) => toast.fire({ icon: 'success', title })
const errorToast = (title) => toast.fire({ icon: 'error', title })


// === 登入 & 取得必要資訊 ===
const { isAccessTokenLoading, accessToken, fetchAccessToken } = useFetchAccessToken()
const { isWabaListLoading, wabaList, fetchWabaList } = useFetchWabaList()
const { isTemplateListLoading, templateList, fetchTemplateList } = useFetchTemplateList()
const { isPhoneNumbersLoading, phoneNumbers, fetchPhoneNumbers } = useFetchPhoneNumbers()
const isStep1Loading = computed(() => {
  return isAccessTokenLoading.value || isWabaListLoading.value || isTemplateListLoading.value || isPhoneNumbersLoading.value
})
// 用 login 取得的 code 再取得 access token
async function getToken(code) {
  console.log('code: ', code)
  try {
    await fetchAccessToken({ code })
  } catch (err) {
    console.error(err)
  }
}
// 取得所有共用 WABA
const wabaOption = ref([])
const wabaValue = ref('')
async function getWabaList() {
  wabaOption.value = []
  try {
    await fetchWabaList()
    wabaOption.value = wabaList.value
  } catch (err) {
    console.error(err)
  }
}
// 取得 Template 清單
const templateOption = ref([])
const templateValue = ref('')
async function getTemplateList(wabaId) {
  templateValue.value = ''
  templateOption.value = []
  try {
    await fetchTemplateList({ wabaId })
    templateOption.value = templateList.value.filter((item) => item.status === 'APPROVED')
  } catch (err) {
    console.error(err)
  }
}
// 取得寄件者手機號碼資訊
const phoneNumberId = ref(null)
async function getPhoneNumberData(wabaId) {
  phoneNumberId.value = null
  try {
    await fetchPhoneNumbers({ wabaId })
    phoneNumberId.value = phoneNumbers.value[0].id
  } catch (err) {
    console.error(err)
  }
}
// login cb
async function handleLogin(response) {
  if (response.authResponse) {
    console.log('FB.login')
    console.log(response)

    if(response.authResponse.code){
      await getToken(response.authResponse.code)
      console.log('token: ', accessToken.value)
      await getWabaList()
      console.log('waba list: ', wabaList.value)
    }
  } else {
    console.log('User cancelled login or did not fully authorize.');
  }
}
// Facebook Login with JavaScript SDK
async function launchWhatsAppSignup() {

  // await getToken('')
  // console.log('token: ', '')
  // await getWabaList()
  // console.log('waba list: ', wabaList.value)
  // if (getWabaList) return

  // Launch Facebook login
  window.FB.login(
    handleLogin,
    {
      config_id: '1125612821885111', // configuration ID obtained in the previous step goes here
      response_type: 'code',     // must be set to 'code' for System User access token
      override_default_response_type: true,
      extras: {
        setup: {
          // Prefilled data can go here
        }
      }
    }
  );
}

// === 選擇 WABA 後, 取得 template 清單
async function handleSelectWaba() {
  await getTemplateList(wabaValue.value)
  console.log('template list: ', templateList.value)
  await getPhoneNumberData(wabaValue.value)
  console.log('phone number data & phone number id: ', phoneNumbers.value, phoneNumberId.value)
}

// === 寄送 Template ===
const { isSending, sendTemplate } = useSendTemplate()
const recipientPhoneNumber = ref('886961302939');
async function send(){
  const target = templateList.value.filter((item) => item.id === templateValue.value)[0]
  try {
    await sendTemplate({
      phoneNumberId: phoneNumberId.value,
      toPhoneNumber: recipientPhoneNumber.value,
      templateName: target.name,
      language: target.language
    })
    successToast('Send Success')
  } catch {
    errorToast('Send Failed')
  }
}
</script>

<style>
.cover {
  inset: 0 0 0 0;
  background: #ffffffcc;
}
</style>
